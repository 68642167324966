.dialogBoxTitle,
.dialogBoxText {
  line-height: 1.5;
  text-align: center;
  font-family: var(--font-base);
}

.dialogBoxTitle {
  font-size: 18px;
  font-weight: bold;
}

.dialogBoxText {
  margin-top: 20px;
  font-size: 16px;
}

.dialogBoxButton {
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;

  @media (--more-than-tablet) {
    flex-direction: row;
  }
  
  .cancel,
  .accept {
    margin-top: 20px;
    letter-spacing: 0.1em;
  }

  .fullWidth {
    width: 100%;
  }

  .halfWidth {
    width: 100%;

    @media (--more-than-tablet) {
      width: calc(50% - 8px);
    }
  } 

  .reversesButtons {
    flex-direction: column-reverse;

    @media (--more-than-tablet) {
      flex-direction: row;
    }
  }
}
