.specifyInput {
  margin-top: 8px;
  display: flex;
  align-items: flex-end;

  input {
    display: block;
    width: calc(100% - 24px);
    height: 48px;
    border-radius: 4px;
    padding: 12px 16px;
    background-color: var(--BG-Form-Dark); 
    border: 1px solid var(--STROKE-Form-Normal);
    font-size: 16px;
    color: var(--TEXT-Base-Light-Primary);
    font-family: inherit;

    &:focus {
      border-color: var(--STROKE-Form-Normal-Focus);
    }

    &::placeholder {
      color: var(--TEXT-Form-Placeholder);
    }

    &.error {
      background-color: var(--BG-Form-Error);
      border-color: var(--STROKE-Form-Error);
    }
  }

  div {
    font-size: 14px;
    width: 24px;
    padding-left: 8px;
    padding-bottom: 3px;
  } 
}

.invalidMessage {
  margin-top: 8px;
  font-size: 12px;
  color: var(--TEXT-Status-Error);
} 
