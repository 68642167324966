.appeal {
  background-color: var(--BG-Base-Dark-Tertiary);
  border-radius: 4px;
  padding: 16px 24px;
  margin-top: 8px;
}

.appealTitle {
  font-weight: bold;
  font-size: 16px;
  line-height: 1.4;
  text-align: center;
  margin-bottom: 16px;
}

.appealTransformation {
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--STROKE-Base-Secondary);
  text-align: center;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.appealTransformationTitle {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.448;
}

.appealTransformationPoints {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.48;
  color: var(--TEXT-Base-Colored-Primary);
}

.appealLink {
  margin-top: 16px;
  text-align: center;

  a {
    font-size: 14px;
    color: var(--TEXT-Base-Light-Primary);
    text-decoration: underline;

    &.appealLinkApp {
      display: inline-block;
      padding-left: 20px;
      background: url(/img/icon_fansta_point_white.svg) no-repeat 0 50%;
    }
  }
}

