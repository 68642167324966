.dialogBoxTitle,
.dialogBoxText,
.dialogBoxConfirm {
  line-height: 1.5;
  text-align: center;
}

.dialogBoxTitle {
  font-size: 17px;
  font-weight: bold;
}

.dialogBoxText {
  margin-top: 20px;
  font-size: 13px;
}

.dialogBoxConfirm {
  margin-top: 20px;

  .checkbox {
    input {
      display: none;
    }

    input:checked + .checkboxText:before {
      background: #333 url(/img/icon_check.svg) no-repeat 49% 50%;
      background-size: 14px auto;
    }
  }

  .checkboxText {
    position: relative;
    display: inline-block;
    padding-left: 32px;
    font-size: 14px;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  }

  a {
    color: #fff;
    font-weight: bold;
  }
}

.dialogBoxButton {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;  

  .accept {
    width: 100%;
    letter-spacing: 0.2em;
  }
}
