.wrapper {
  width: 100%;
  pointer-events: none;

  &.floating {
    position: fixed;
    padding: 16px;
    top: 0;
    left: 0;
    z-index: 999;
  }
}

.box {
  display: flex;
  margin-bottom: 12px;
  padding: 16px 16px 16px 44px;
  background-repeat: no-repeat;
  background-position: 16px 16px;
  background-size: 20px auto;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  color:  var(--TEXT-Base-Dark-Primary);
  pointer-events: auto;

  a {
    color: inherit;
    text-decoration: underline;
    margin: 0 4px;
  }

  p + p {
    margin-top: 12px;
  }
}

.text {
  flex: 1;
  word-break: break-all;
  font-size: 14px;
  line-height: 1.5;
}

.close {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-left: 8px;
  background: url(/img/icon/close_gray.svg) no-repeat center center;
  background-size: 20px auto;
  border: none;
}

.success {
  background-color: var(--BG-Status-Success);
  background-image: url(/img/icon/success.svg);
}

.caution {
  background-color: var(--BG-Status-Warning);
  background-image: url(/img/icon/caution.svg);
}

.error {
  background-color: var(--BG-Status-Error);
  background-image: url(/img/icon/error.svg);
}

.information {
  background-color: var(--BG-Status-Info);
  background-image: url(/img/icon/information.svg);
}

.strongLink {
  color: var(--TEXT-Base-Dark-Primary);
  font-weight: bold;
  text-decoration: underline;
}
