.box {
  margin-top: 32px;

  @media (--more-than-tablet) {
    margin-top: 0;
  }

  &:first-child{
    margin-top: 20px;
  }
}

.title {
  padding-bottom: 12px;

  @media (--more-than-tablet) {
    padding-top: 20px;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 100;
    background-color: var(--color-bg-black);
  }
}

.contentTitle {
  font-size: 15px;
}

.link {
  display: block;
  text-decoration: none;

  * {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
}

.ribbon {
  display: block;
  width: 81px;
  height: 61px;
  background: transparent url(/img/ribbon.png) no-repeat top right;
  background-size: contain;
  position: absolute;
  top: -4px;
  right: -4px;
}

.ribbon .label {
  position: relative;
  display: block;
  transform: rotate(37.18deg);
  top: 19px;
  left: 9px;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  color: var(--TEXT-Tag-Light);
}

.broadcastBox {
  background:  var(--BG-Base-Dark-Tertiary);
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }

  .team {
    padding: 12px 14px 12px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
  }

   .contentsHolderLogo {
     width: 36px;
     height: 36px;
     position: absolute;
     top: 0;
     left: 0;
   }
 
  &.bgBlue {
    background: #003D75;
  }
}

.team {
  .cat {
    flex: 1;
  }

  .tournamentName{
    margin-top: 12px;
    margin-bottom: 4px;
    font-size: 10px;
    font-style: normal;
    text-align: center;
    color: var(--TEXT-Base-Light-Primary);
    white-space: pre-line;
  }
  .tournamentDate {
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    text-align: center;
    color: var(--TEXT-Base-Light-Primary);
    line-height: 1.4;
  }
}

.cat {
  .logo {
    @media screen and (max-width: 320px) {
      width: 52px;
    }
    
    display: block;
    width: 64px;
    height: 64px;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% auto;
  }

  .name {
    padding-top: 4px;
    font-size: 15px;
    font-weight: 900;
    color: var(--TEXT-Base-Light-Primary);
    text-align: center;
  }
}

.inner {
  padding: 8px 16px;
  background-color: rgba(255, 255, 255, 0.1);
}

.btn {
  width: 100%;
  height: 30px;
  font-size: 13px;
}
