.title {
  > div {
    margin-bottom: 16px;
  }
}

.column {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.rows {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.note {
  font-size: 14px;
  color: var(--TEXT-Base-Light-Tertiary);
}

.label {
  font-size: 16px;
  color: var(--TEXT-Base-Light-Primary);
}

.pointsBreakdown {
  text-align: right;
  font-size: 14px;
  line-height: 1.48;

  button {
    margin: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
    color: var(--TEXT-Base-Light-Tertiary);
    text-decoration: underline;
  }
}

.availablePoint {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1.48;
  margin-bottom: 24px;

  .availablePointLabel {
    font-size: 16px;
  }

  .availablePointNumber {
    font-size: 16px;

    em {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      color: var(--TEXT-Base-Colored-Primary);
      margin-bottom: 16px;
    }
  }
}

.btn {
  width: 100%;
  letter-spacing: 0.1em;
}

