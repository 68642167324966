.wrapper {
  min-height: calc(100vh - 62px);
  min-height: calc(100svh - 62px);  /* iOS Safariの場合はアドレスバーを考慮する */
  padding: 20px 0 93px;

  @media (--more-than-tablet) {
    padding-top: 40px;
  }

  & :global(.l-main__innerWidth) {
    @media (--more-than-tablet) {
      max-width: 624px;
    }
  }
}
