.registerFrom {
  margin-top: 10px;
  margin-bottom: 6px;
}

.appeal {
  background-color: var(--BG-Base-Dark-Tertiary);
  border-radius: 4px;
  padding: 16px 24px;
}

.appealTitle {
  font-weight: bold;
  font-size: 16px;
  line-height: 1.4;
  text-align: center;
  margin-bottom: 16px;
}

.appealTransformation {
  border-radius: 8px;
  border: 1px solid var(--STROKE-Base-Secondary);
  text-align: center;
  padding: 16px;
  display: flex;
  flex-direction: column;
  column-gap: 8px;
}

.appealTransformationTitle {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.448;
}

.appealTransformationPercentage {
    font-size: 18px;
    line-height: 1.48;
    font-weight: bold;
    color: var(--TEXT-Base-Colored-Primary);
}

.appealLink {
  margin-top: 16px;
  text-align: center;

  a {
    font-size: 14px;
    color: var(--TEXT-Base-Light-Primary);
    text-decoration: underline;

    &.appealLinkApp {
      display: inline-block;
      padding-left: 20px;
      background: url(/img/icon_fansta_point_white.svg) no-repeat 0 50%;
    }
  }
}

.registerCheck {
  display: block;
  margin-top: 16px;

  input {
    &:checked {
      + .registerCheckLabels {
        &::before {
          background-color: var(--BG-Checkbox-Primary);
        }

        &::after {
          opacity: 1;
        }
      }
    }
  }

  .registerCheckLabels {
    position: relative;
    display: block;
    padding-left: 32px;
    color: var(--TEXT-Base-Light-Primary);
    line-height: 1.49;

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 24px;
      height: 24px;
    }

    &::before {
      top: auto;
      left: 0;
      border-radius: 4px;
      border: 1px solid var(--STROKE-RadioButton-Primary);
    }

    &::after {
      top: 0;
      left: 0;
      background: url(/img/icon/check.svg) no-repeat 50% 50%;
      background-size: 14px auto;
      opacity: 0;
    }
  }
}
