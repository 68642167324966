.header {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  position: relative;
  background-color: #1E1E1E;

  @media (--more-than-tablet) {
    background-color: transparent;
    padding: 32px 80px 0;
  }
}

.headerFixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.headerLogo {
  width: 90px;

  @media (--more-than-tablet) {
    width: 136px;
  }

  a {
    display: block;
    color: #fff;
    text-decoration: none;
  }
}

.headerText {
  margin-top: 4px;
  font-size: 10px;

  @media (--more-than-tablet) {
    margin-top: 8px;
    font-size: 14px;
  }
}

.appDownloadButtons{
  display: flex;
  align-items: center;
  column-gap: 8px;

  &.isWevView{
    display: none;
  }

  @media (--more-than-tablet) {
    column-gap: 16px;
  }

  img{
    width: auto;
    max-height: 32px;

    @media (--more-than-tablet) {
      max-height: 48px;
    }
  }
}
