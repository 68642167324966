.modal{
  padding: 4px;
}

.modalTitle{
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.modalContents{
  margin-top: 24px;
  border-top: 1px solid var(--STROKE-Base-Secondary);
}

.pointItem {
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  color: var(--TEXT-Base-Light-Primary);
}

.pointItemTitle {
  font-weight: bold;
  font-size: 14px;
}

.pointItemData {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 14px;

  li {
    em{
      color: var(--TEXT-Base-Colored-Primary);
      font-style: normal;
    }
  }
}

.pointItemNote {
  margin-top: 8px;
  font-size: 12px;
  color: var(--TEXT-Base-Light-Tertiary);
}
