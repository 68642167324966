.dialog {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.dialogInner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 16px;
  overflow: auto;
}

.dialogBox {
  position: relative;
  width: 91.46667%;
  max-width: 464px;
  padding: 20px;
  background-color: var(--BG-Base-Dark-Tertiary);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  margin: auto;
}

.closeBtn {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 28px;
  height: 28px;
  appearance: none;
  border-radius: 50%;
  border: none;
  background-color: var(--BG-Base-Light-Primary);
  font-size: 0;
  color: transparent;

  &.iconCloseButton{
    padding: 0;
    border-radius: 0px;
    background-color: transparent;
    width: 24px;
    height: 24px;
    top: 8px;
    right: 8px;
  }
}

