.footer {
  padding: 30px 16px;
  font-family: "Noto Sans JP", sans-serif;
  background: var(--BG-Base-Dark-Basic);

  &.fixdedButtonSpace{
    &[class*=isNewsFixdedButton]{
      margin-bottom: 30px;
      padding-bottom: 70px;

      @media (--more-than-tablet) {
        margin-bottom: 0;
        padding-bottom: 30px;
      }
    }
  }
}

.footerInner{
  max-width: 990px;
  margin: 0 auto;
}

.footerTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-bottom: 28px;
  border-bottom: 1px solid var(--STROKE-Base-Secondary);
}

.footerLogo {
  display: flex;
  align-items: center;

  a {
    margin-right: 8px;
    max-width: 71px;
  }
}

.footerText {
  font-size: 10px;
}

.footerSns {
  display: flex;

  li + li {
    margin-left: 20px;
  }
}

.footerList {
  margin-bottom: 20px;
}

.footerItem {
  font-size: 15px;
  line-height: 1.5;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.footerLink {
  color: var(--TEXT-Link-Primary);
}

.footerCopy {
  font-size: 15px;
  text-align: center;
}
