.flowList{
  display: flex;
  color: var(--TEXT-Base-Colored-Primary);
  font-size: 12px;

  @media (--more-than-tablet) {
    font-size: 14px;
  }

  .line{
    flex: 1 0 0;
    margin-top: 8px;
    height: 1px;
    background-color: var(--STROKE-Base-Colored-Primary);
    width: 100%;
    opacity: 1;
    
    &.incomplete{
      opacity: 0.5;
    }
  }
  
  .valueWrapper {
    flex: 1 0 0;
    min-width: 88px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    opacity: 1;

    &.incomplete{
      opacity: 0.5;
    }
  }
}
