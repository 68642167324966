.btn {
  width: 100%;
  letter-spacing: 0.1em;
}

.btnWrapper {
  margin-top: 16px;
  margin-bottom: 16px;
  
  @media (--more-than-tablet) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.padding {
  /* ボタン下部固定のときに適度に間隔をあけるため、高さを調整している */
  height: 120px;  
  @media (--more-than-tablet) {
    height: 0;
  }
}

.buttons {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 16px;
  background-color: var(--BG-Base-Dark-Secondary);
  z-index: 10;
  
  @media (--more-than-tablet) {
    position: relative;
    padding: 0;
    background-color: transparent;
  }
}

.form {
  @media (--more-than-tablet) {
    margin-top: 40px;
  }
}

.title {
  > div {
    height: auto;

    @media (--more-than-tablet) {
      padding-bottom: 16px;
    }
  }
}

.link {
  text-align: center;

  a {
    color: #fff;
    font-size: 14px;
  }
}

.event {
  + .event {
    margin-top: 10px;
  }
}

.point {
  margin-bottom: 24px;
}

.others {
  margin-top: 24px;
  margin-bottom: 24px;
  line-height: 1.48;
}

.othersItemCard {
  display: inline-flex;
}

.othersItemCardNumber {
  padding-left: 8px;
}

.othersItemTotal {
  font-weight: bold;
  font-size: 24px;
  color: var(--TEXT-Base-Colored-Primary);
}

.pointNote {
  color: var(--TEXT-Base-Light-Tertiary);
  text-align: right;
  margin-top: 8px;
  font-size: 14px;
  line-height: 1.48;
}

.cancelNote{
  display: grid;
  row-gap: 24px;
}

.points {
  display: flex;
}

.pointIcon {
  display: flex;
  align-items: center;
  width: 16px;
  margin-left: 8px;
  margin-right: 4px;
}

.pointsButton {
  font-size: 14px;
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: var(--TEXT-Base-Light-Tertiary);
  text-decoration: underline;
}
