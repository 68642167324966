/* 現状、public/css/style.cssに共通項目(fontのimportとか)が入っているが、最終的にここやsettingsに移行する。 */
@import "settings.css";

* {
  box-sizing: border-box;
  outline: none;
}

/*
  For scrollbar width getter
  SEE: https://gist.github.com/ciases/aeff65950f2bbafc8da699d1711fb7f3
 */
.helper-scrollbar-width {
  position: absolute;
  top: -9999px;
  width: 100px;
  height: 100px;
  overflow: scroll;
}

html.is-scroll-disabled {
  overflow: hidden;

  body {
    /* CSS specific to iOS devices */
    @supports (-webkit-touch-callout: none) {
      position: fixed;
      width: 100%;
    }
  }
}

body {
  word-wrap: break-word;
  -webkit-text-size-adjust: 100%;
  position: relative;
  background-color: var(--BG-Base-Dark-Primary);
  font-family: var(--font-base);
  color: var(--TEXT-Base-Light-Primary);
  min-width: 320px;
}

img {
  width: 100%;
  vertical-align: bottom;
}

a,
button {
  @media (--more-than-tablet) {
    transition: opacity 0.3s;
  }

  &:hover {
    @media (--more-than-tablet) {
      opacity: 0.8;
    }
  }
}

.none {
  display: none;
}

.relative {
  position: relative;
}

@media (--more-than-tablet) {
  .md\:block {
    display: block;
  }

  .md\:none {
    display: none;
  }
}

#recaptchaContainer {
  display: none;
}

/* Apps Flyerのバナーが2個以上表示されても1つしか表示しないようにする */
.responsive-wrapper:not(:first-child) {
  display: none !important;
}
