/* タブエリア */
.tournamentTabWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(13%, 1fr));
  column-gap: 8px;
  padding: 4px;
  background-color: var(--BG-Base-Dark-Primary);
  border-radius: 12px;
}

.inner {
  padding: 20px;
}

.tournamentTabItem {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  color: var(--TEXT-Base-Light-Primary);
  font-size: 12px;
  text-decoration: none;
  text-align: center;
  border: none;
  background-color: var(--BG-Base-Dark-Secondary);
  cursor: pointer;
  border-radius: 10px;

  &:hover {
    text-decoration: underline;
  }

  &.isActive {
    color: var(--TEXT-Tab-Primary);
    background-color: var(--BG-Tab-Dark-Secondary);
    text-decoration: none;
  }
}
