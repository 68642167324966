.applicablePoints {
  font-size: 14px;
  line-height: 1.448;
  margin-top: 8px;

  span {
    color: var(--TEXT-Base-Colored-Primary);
  }

  em {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
  }
}
