.card {
  background-color: var(--BG-Card-Normal);
  border-radius: 8px;
  overflow: hidden;
}

.cardMain {
  padding: 16px;
}

.cardDetails {
  background-color: var(--BG-Card-Light);
  cursor: pointer;

  summary {
    padding: 16px;
    font-size: 14px;
    font-weight: bold;
    color: var(--TEXT-Base-Light-Secondary);
    position: relative;
    
    &::-webkit-details-marker {
      display:none;
    }

    &::after {
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      background: url(/img/icon/arrow_under.svg) no-repeat 100% 50%;
      position: absolute;
      top: 16px;
      right: 16px;
      z-index: 1;
    }
  }

  &[open] {
    summary {
      &::after {
        transform: rotate(180deg);
      }
    }
  }
}

.cardDetailsBody {
  padding: 0 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.shopName {
  font-size: 16px;
  line-height: 1.49;
  margin-bottom: 4px;
}

.date {
  font-size: 14px;
  line-height: 1.48;
  color: var(--TEXT-Base-Light-Tertiary);
  margin-bottom: 8px;
}

.planName {
  font-weight: bold;
  font-size: 16px;
  line-height: 1.4;
}

.cardPrice {
  margin-top: 24px;
  font-size: 16px;
  line-height: 1.48;
  color: var(--TEXT-Base-Light-Tertiary);
}

.cardPriceItem {
  display: flex;
  align-items: center;
  justify-content: space-between;

  + .cardPriceItem {
    margin-top: 8px;
  }
}

.cardPriceTotal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--STROKE-Base-Secondary);
  padding-top: 8px;
  margin-top: 8px;
  line-height: 1.49;

  em {
    font-weight: bold;
    font-style: normal;
    font-size: 20px;
    color: var(--TEXT-Base-Light-Primary);
  }
}
