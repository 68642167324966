.registeredCard {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.registeredCardIcon {
  width: 47px;
}

.registeredCardNumber {
  width: calc(100% - 47px);
  font-size: 16px;
  color: var(--TEXT-Base-Light-Tertiary);
  padding-left: 8px;
}
