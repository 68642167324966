.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  text-decoration: none;
  font-size: 15px;
  font-weight: 700;
  border: none;
  border-radius: 4px;
}

.primary{
  &.filled{
    background-color: var(--BG-Button-Primary);
    color: var(--TEXT-Button-Filled-Primary);

    @media (hover: hover){
      &:hover {
        background: var(--BG-Button-Primary-Hover);
        color: var(--TEXT-Button-Filled-Primary-Hover);
      }
    }

    &:focus {
      color: var(--TEXT-Button-Filled-Primary-Focus);
      background-color: var(--BG-Button-Primary-Focus);
      box-shadow: 0px 0px 8px 0px var(--BRAND-Base);
    }

    &:disabled{
      background: var(--BG-Button-Primary-Disabled);
      color: var(--TEXT-Button-Filled-Primary-Disabled);
    }
  }

  &.outlined{
    background-color: transparent;
    border: 1px solid var(--STROKE-Button-Primary);
    color: var(--TEXT-Button-Outlined-Primary);

    @media (hover: hover){
      &:hover {
        border: 1px solid var(--STROKE-Button-Primary-Hover);
        color: var(--TEXT-Button-Outlined-Primary-Hover);
      }
    }

    &:focus {
      border: 1px solid var(--STROKE-Button-Primary-Focus);
      color: var(--TEXT-Button-Outlined-Primary-Focus);
      box-shadow: 0px 0px 8px 0px var(--BRAND-Base);
    }


    &:disabled{
      color: var(--TEXT-Button-Outlined-Primary-Disabled);
      border-color: var(--STROKE-Button-Primary-Disabled);
    }
  }
}

.secondary{
  &.filled{
    background-color: var(--BG-Button-Secondary);
    color: var(--TEXT-Button-Filled-Secondary);

    @media (hover: hover){
      &:hover {
        background: var(--BG-Button-Secondary-Hover);
        color: var(--TEXT-Button-Filled-Secondary-Hover);
      }
    }

    &:focus {
      background-color: var(--BG-Button-Secondary-Focus);
      color: var(--TEXT-Button-Filled-Secondary-Focus);
      box-shadow: 0px 0px 8px 0px var(--WHITE-White);
    }

    &:disabled{
      background: var(--BG-Button-Secondary-Disabled);
      color: var(--TEXT-Button-Filled-Secondary-Disabled);
    }
  }

  &.outlined{
    background-color: transparent;
    border: 1px solid var(--STROKE-Button-Secondary);
    color: var(--TEXT-Button-Outlined-Secondary);

    @media (hover: hover){
      &:hover {
        border: 1px solid var(--STROKE-Button-Secondary-Hover);
        color: var(--TEXT-Button-Outlined-Secondary-Hover);
      }
    }

    &:focus {
      background-color: var(--BG-Button-Secondary-Focus);
      color: var(--TEXT-Button-Filled-Secondary-Focus);
      box-shadow: 0px 0px 8px 0px var(--BRAND-Base);
    }

    &:disabled{
      color: var(--TEXT-Button-Outlined-Secondary-Disabled);
      border-color: var(--STROKE-Button-Secondary-Disabled);
    }
  }
}

.tertiary{
  &.filled{
    background-color: var(--BG-Button-Tertiary);
    color: var(--TEXT-Button-Filled-Tertiary);

    @media (hover: hover){
      &:hover {
        background: var(--BG-Button-Tertiary-Hover);
        color: var(--TEXT-Button-Filled-Tertiary-Hover);
      }
    }

    &:focus {
      background-color: var(--BG-Button-Tertiary-Focus);
      color: var(--TEXT-Button-Filled-Tertiary-Focus);
      box-shadow: 0px 0px 8px 0px var(--BLUE-Light40);
    }

    &:disabled{
      background: var(--BG-Button-Tertiary-Disabled);
      color: var(--TEXT-Button-Filled-Tertiary-Disabled);
    }
  }

  &.outlined{
    background-color: transparent;
    border: 1px solid var(--STROKE-Button-Tertiary);
    color: var(--TEXT-Button-Outlined-Tertiary);

    @media (hover: hover){
      &:hover {
        border: 1px solid var(--STROKE-Button-Tertiary-Hover);
        color: var(--TEXT-Button-Outlined-Tertiary-Hover);
      }
    }

    &:focus {
      color: var(--TEXT-Button-Outlined-Tertiary-Focus);
      box-shadow: 0px 0px 8px 0px var(--BRAND-Base);
    }

    &:disabled{
      color: var(--TEXT-Button-Outlined-Tertiary-Disabled);
      border-color: var(--STROKE-Button-Tertiary-Disabled);
    }
  }
}

.delete{
  &.filled{
    background-color: var(--BG-Button-Delete);
    color: var(--TEXT-Button-Filled-Delete);

    @media (hover: hover){
      &:hover {
        background: var(--BG-Button-Delete-Hover);
        color: var(--TEXT-Button-Filled-Delete-Hover);
      }
    }

    &:focus {
      color: var(--TEXT-Button-Filled-Delete-Focus);
      background-color: var(--BG-Button-Delete-Focus);
      box-shadow: 0px 0px 8px 0px var(--RED-Light40);
    }

    &:disabled{
      background: var(--BG-Button-Delete-Disabled);
      color: var(--TEXT-Button-Filled-Delete-Disabled);
    }
  }

  &.outlined{
    background-color: transparent;
    border: 1px solid var(--STROKE-Button-Delete);
    color: var(--TEXT-Button-Outlined-Delete);

    @media (hover: hover){
      &:hover {
        border: 1px solid var(--STROKE-Button-Delete-Hover);
        color: var(--TEXT-Button-Outlined-Delete-Hover);
      }
    }

    &:focus {
      border: 1px solid var(--STROKE-Button-Delete-Focus);
      color: var(--TEXT-Button-Outlined-Delete-Focus);
      box-shadow: 0px 0px 8px 0px var(--RED-Light40);
    }


    &:disabled{
      color: var(--TEXT-Button-Outlined-Delete-Focus);
      border-color: var(--STROKE-Button-Delete-Disabled);
    }
  }
}
