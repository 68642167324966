.main {
  @media (--more-than-tablet) {
    padding: 40px 0 44px;
    background-color: var(--color-bg-black);
    min-height: 814px;
  }
}

.box {
  margin-top: 20px;
}

.notfound {
  margin: 40px 0;
  color: #fff;
  font-size: 15px;
  text-align: center;

  @media (--more-than-tablet) {
    margin-top: 80px;
    margin-bottom: 0;
  }
}

.filter {
  margin-bottom: 16px;

  & :global(.l-main__innerWidth) {
    @media (--more-than-tablet) {
      width: 100%;
    }
  }
}

.container {
  @media (--more-than-tablet) {
    width: 100%;
  }
}

.column {
  @media (--more-than-tablet) {
    display: flex;
    justify-content: space-between;
    max-width: 990px;
    min-height: 730px;
    margin: 0 auto;
    padding: 0 16px;
  }
}

.columnLeft {
  @media (--more-than-tablet) {
    position: relative;
    width: 288px;
    flex-shrink: 0;
    padding-bottom: 400px;
    z-index: 101;
  }
}

.columnRight {
  @media (--more-than-tablet) {
    position: relative;
    width: calc(100% - 288px - 32px);
    margin-left: 32px;
    z-index: 1;
  }
}

.sticky {
  @media (--more-than-tablet) {
    position: sticky;
    top: 20px;
    z-index: 1;
  }
}

.pager {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.arrow {
  position: relative;
  color: #ccc;
  font-size: 15px;
  text-decoration: underline;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 9px;
    height: 15px;
    margin: auto 0;
    background: url(/img/icon_arrow_left.svg) no-repeat center center;
    background-size: 9px 15px;
  }

  &.disabled {
    opacity: 0.6;
    text-decoration: none;
  }
}

.arrowPrev,
.arrowNext {
  background: none;
  border: none;
}

.arrowPrev {
  padding-left: 22px;

  &:after {
    left: 0;
  }
}

.arrowNext {
  padding-right: 22px;

  &:after {
    right: 0;
    transform: rotate(180deg) translateY(1px);
  }
}
