.radio {

  input {
    display: none;

    &:checked {
      + .label {
        border: 2px solid var(--STROKE-Base-Colored-Secondary);

        &::after {
          opacity: 1;
        }
      }
    }
  }

  .label {
    position: relative;
    display: block;
    padding: 16px;
    padding-left: 48px;
    background-color: var(--BG-Card-Normal);
    border: 2px solid transparent;
    color: var(--TEXT-Base-Light-Primary);
    border-radius: 8px;

    &::before,
    &::after {
      content: "";
      position: absolute;
      border-radius: 50%;
    }

    &::before {
      top: 14px;
      left: 14px;
      width: 24px;
      height: 24px;
      border: 1px solid var(--STROKE-RadioButton-Primary);
    }

    &::after {
      top: 18px;
      left: 18px;
      width: 16px;
      height: 16px;
      background-color: var(--BG-Checkbox-Secondary);
      opacity: 0;
    }
  }

  .pointLabel {
    font-weight: bold;
    font-size: 16px;
    line-height: 1.4;
  }
}
