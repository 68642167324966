.wrapper {
  background-color: #323232;

  @media (--more-than-tablet) {
    background-color: inherit;
    min-height: inherit;
  }
}

.pointWrapper {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  justify-content: space-between;
  background-color: #323232;
  line-height: 1.5;

  @media (--more-than-tablet) {
    margin-right: 16px;
    border-radius: 48px;
    justify-content: center;
  }
}

.pointText {
  font-size: 12px;
}

.pointDetail {
  margin-left: 8px;
}

.pointNum {
  color: var(--color-yellow);
  font-weight: bold;
}

.pointUnit {
  margin-left: 4px;
}

.noPoint {
  justify-content: center;

  .pointText {
    padding-left: 20px;
    background: url(/img/icon_fansta_point.svg) no-repeat left center;
  }
}

.invisible {
  visibility: hidden;
}

.link {
  text-decoration: none;
  color: #fff;
}
