.num {
  position: absolute;
  top: -6px;
  left: calc(100% - 19px);
  min-width: 16px;
  padding: 0 4px;
  background-color: #ee3b3b;
  border-radius: 13px;
  color: #fff;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
}
