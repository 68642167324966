.cover {
  position: fixed;
  top: 0;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: var(--z-index-dialog);
}

.root {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: var(--z-index-dialog);
  pointer-events: none;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  &:before {
    content: "";
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.4s;
  }
}

.content {
  position: relative;
  width: 100%;
  transition: bottom 0.4s;
  --delta-y: 0px;
  transform: translate3d(0, var(--delta-y), 0);
  will-change: transform;
}

.open {
  pointer-events: auto;

  .overlay:before {
    opacity: 1;
  }

  .content {
    bottom: 0;
  }
}

.border {
  position: relative;
  width: 100px;
  height: 30px;
  margin: 0 auto 20px;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    width: 52%;
    height: 4px;
    background-color: var(--STROKE-Tab-Secondary);
    border-radius: 2px;
    transition: transform 0.7s ease-in;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }
}

.borderDown {
  &:before,
  &:after {
    transition-duration: 0.3s;
  }
  &:before {
    transform: rotate(15deg);
  }

  &:after {
    transform: rotate(-15deg);
  }
}

/* フォーム共通部分 */
.items {
  height: calc(100vh - 100px - 60px - 66px);
  background: var(--BG-Base-Dark-Quaternary);
  overflow-y: auto;
}
@supports (-webkit-touch-callout: none) {
  .items {
    height: calc(85vh - 100px - 60px - 66px);
  }
}

.form {
  display: flex;
  flex-direction: column;
}

.itemMain,
.itemSub {
  font-size: 14px;
  color: var(--TEXT-Base-Dark-Primary);
}

.itemMain {
  background: var(--BG-Tab-Dark-Tertiary);

  .areaCheckText {
    font-weight: bold;
  }
}

.itemSub {
}

.items > div:last-child .itemSub{
  border-bottom: 1px solid var(--STROKE-Base-Secondary);
}

/* エリア検索部分 */
.areaSub {
  display: flex;
  flex-wrap: wrap;

  .areaCheck {
    width: 50%;
    margin-bottom: -1px;

    &:nth-child(odd) {
      border-right: 1px solid var(--STROKE-Base-Secondary);
    }

    &:nth-child(2):not(:last-child) {
      border-bottom: 1px solid var(--STROKE-Base-Secondary);
    }

    &:nth-child(n+3) {
      border-top: 1px solid var(--STROKE-Base-Secondary);
    }
  }
}

.areaCheck {
  display: block;
  padding: 14px 16px;

  input {
    display: none;
  }

  input:checked + .areaCheckText:before {
    background: var(--BG-Checkbox-Primary) url(/img/icon_check.svg) no-repeat 49% 50%;
    background-size: 14px auto;
  }
}

.areaCheckText {
  position: relative;
  padding-left: 32px;
  color: var(--TEXT-Base-Light-Primary);

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    background: transparent;
    border: 1px solid var(--STROKE-Checkbox-Primary);
    border-radius: 4px;
  }
}

.areaButtons {
  display: flex;
  background: var(--BG-Base-Dark-Basic);
  padding: 8px 16px;
}

.reset,
.submit {
  border: none;
}

.reset {
  width: 77px;
  flex-shrink: 0;
}

.submit {
  flex: 1;
  margin-left: 12px;
}

/* チーム検索部分 */
.teamItems {
  height: calc(100vh - 100px - 60px - 66px - 66px);
  overflow-y: auto;
}
@supports (-webkit-touch-callout: none) {
  .teamItems {
    height: calc(85vh - 100px - 60px - 66px - 66px);
  }
}
.teamItemsNoTabs {
  height: calc(100vh - 100px - 60px - 66px);
}
@supports (-webkit-touch-callout: none) {
  .teamItemsNoTabs {
    height: calc(85vh - 100px - 60px - 66px);
  }
}
.teamItemMain {
  overflow-x: auto;
}

.teamGenre {
  display: flex;
  padding: 12px 16px;
  font-family: var(--font-noto);
  overflow-x: auto;
  align-items: center;
  background: var(--BG-Tab-Dark-Primary);
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.teamGenreItem {
  flex-shrink: 0;
  margin-right: 8px;
  border-radius: 50px;
  padding: 8px 16px;
  color: var(--TEXT-Base-Light-Primary);
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  background: var(--BG-Base-Dark-Secondary);

  &:last-child {
    position: relative;
    margin-right: 0;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 100%;
      width: 16px;
      height: 1px;
    }
  }

  .tournament {
    display: block;
    line-height: 1;
    font-size: 12px;
    white-space: pre-line;
  }

  &.isActive {
    background: var(--BG-Tab-Dark-Secondary);
    color: var(--TEXT-Tab-Primary);
  }
}

.teamCheck {
  display: block;
  border-bottom: 1px solid var(--STROKE-Base-Secondary);

  input {
    display: none;
  }

  input:checked + .teamDetail:before {
    background: var(--BG-Checkbox-Primary) url(/img/icon/check.svg) no-repeat 49% 50%;
    background-size: 14px auto;
  }
}

.teamDetail {
  display: flex;
  align-items: center;
  position: relative;
  padding: 4px 16px 4px 48px;
  min-height: 48px;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    border: 1px solid var(--STROKE-Checkbox-Primary);
    border-radius: 4px;
  }

  .logo {
    width: 36px;
    flex-shrink: 0;
    margin-right: 8px;
  }

  .name {
    flex: 1;
    color: var(--TEXT-Base-Light-Primary);
  }
}

/* チーム絞り込み部分 */
.teamFilterCheck {
  display: block;

  input:checked + .teamFilterDetail:before {
    background: var(--BG-Checkbox-Primary) url(/img/icon/check.svg) no-repeat 49% 50%;
    background-size: 14px auto;
  }
}

.teamFilterDetail {
  position: relative;
  padding-left: 48px;
  min-height: 48px;
  background-color: var(--BG-Base-Dark-Quaternary);

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    background-color: transparent;
    border: 1px solid var(--STROKE-Checkbox-Primary);
    border-radius: 4px;
  }
}

.teamFilterButtons {
  display: flex;
  background-color:  var(--BG-Base-Dark-Basic);
  padding: 8px 16px;
  border-top: 1px solid var(--STROKE-Base-Secondary);
}

@keyframes slideIn {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
  }
  40%,
  100% {
    opacity: 1;
  }
}

@keyframes slideOut {
  0% {
    transform: translateY(0%);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
  }
  60%,
  100% {
    opacity: 0;
  }
}

.slideIn {
  animation: slideIn 0.4s cubic-bezier(0.25, 1, 0.5, 1) 1 forwards;
  will-change: opacity, transform;
}

.slideOut {
  animation: slideOut 0.3s cubic-bezier(0.25, 1, 0.5, 1) 1 forwards;
  will-change: opacity, transform;
}

.borderArea {
  width: 100%;
  height: 100px;
  padding-top: 80px;
  touch-action: none;
}

.hidden {
  display: none;
}

.overflowHidden {
  overflow: hidden;
}
