.headerContact {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  width: 108px;

  @media (--more-than-tablet) {
    right: 32px;
    width: 96px;
  }

  .btn {
    height: 40px;
    font-size: 12px;

    @media (--more-than-tablet) {
      height: 38px;
    }
  }
}

.btns {
  display: flex;
  align-items: center;

  .login,
  .signup {
    width: 72px;
    height: 38px;
    font-size: 12px;
    margin-left: 8px;
    white-space: nowrap;
  }

  .login {
    background-color: transparent;
  }

  .mypage,
  .notifications {
    color: #fff;
    text-decoration: none;
    font-size: 10px;

    &:before {
      content: "";
      display: block;
      margin: 0 auto 4px;
      width: 20px;
      height: 20px;
    }
  }

  .mypage {
    &:before {
      background: url(/img/icon_mypage.svg) no-repeat center center;
    }
  }

  .notifications {
    position: relative;
    margin-right: 20px;

    &:before {
      background: url(/img/icon_notifications.svg) no-repeat center center;
    }
  }
}
