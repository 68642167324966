.policy {
  border: 1px solid var(--STROKE-Base-Tertiary);
  padding: 16px;
  border-radius: 8px;
  line-height: 1.48;
}

.policyTitle {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 8px;
}

.policyText {
  font-size: 14px;
  color: var(--TEXT-Base-Light-Tertiary);
}
