.textEllipsis {
  appearance: none;
  white-space: nowrap;
  overflow: hidden;
}

.filterLabel {
  width: 101%;
  height: 50px;
  appearance: none;
  outline: none;
  background: none;
  border: none;
  color: var(--TEXT-Base-Dark-Primary);
  transform: scale(0.9);
  transform-origin: center left;
  pointer-events: none;
}

.filterLabel::-ms-expand {
  display: none;
}

.filterLabel.isEmpty {
  color: var(--TEXT-Form-Placeholder);
}

.inputFade {
  position: absolute;
  top: 0;
  right: 30px;
  bottom: 0;
  display: block;
  content: "";
  width: 32px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}
