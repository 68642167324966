/* フォーム共通部分 */
.wrapper {
  position: absolute;
  top: calc(100% + 4px);
  z-index: 100;
  width: 100vw;
  background: var(--BG-Base-Dark-Quaternary);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  overflow: hidden;

  &.areaWrappr {
    max-width: 716px;
    &.compact {
      max-width: min(716px, 200%);
    }
  }

  &.teamWrappr {
    max-width: 770px;
    &.compact {
      max-width: min(770px, 200%);
    }
  }

  &.right {
    right: 0;
  }
}

.inner {
  padding: 20px;
  overflow: auto;
  max-height: 800px;
}

.items {
  display: flex;
  flex-wrap: wrap;
}

.areaItems {
  margin: 4px 0 0;
  overflow-y: scroll;
  max-height: 20vh;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.3);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }

  /* チーム選択の高さは画面の高さに応じて伸ばす。 */
  @media screen and (min-height: 700px) {
    max-height: 27vh;
  }

  @media screen and (min-height: 800px) {
    max-height: 35vh;
  }

  @media screen and (min-height: 900px) {
    max-height: 42vh;
  }
}

.teamItems {
  margin: 4px 0 0;
  overflow-y: scroll;
  max-height: 20vh;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.3);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }

  /* チーム選択の高さは画面の高さに応じて伸ばす。 */
  @media screen and (min-height: 700px) {
    max-height: 27vh;
  }
  
  @media screen and (min-height: 800px) {
    max-height: 35vh;
  }

  @media screen and (min-height: 900px) {
    max-height: 42vh;
  }
}

.w-1\/3 {
  width: 33.3%;
}

.item {
  margin-top: 24px;

  &.team {
    margin-top: 20px;
  }
}

.itemSub {
  margin-left: 30px;

  .check {
    margin-top: 24px;
  }
}

.check {
  height: 24px;
  display: inline-block;

  &.team {
    height: 36px;
  }

  input {
    display: none;
  }

  input:checked + .checkText:before {
    background: var(--BG-Checkbox-Primary) url(/img/icon/check.svg) no-repeat 49% 50%;
    background-size: 14px auto;
  }
}

.checkText {
  position: relative;
  padding-left: 32px;
  color: var(--TEXT-Base-Light-Primary);
  font-size: 13px;

  & > span {
    line-height: 24px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    background-color: transparent;
    border: 1px solid var(--STROKE-Checkbox-Primary);
    border-radius: 4px;
  }
}

/* ボタンエリア */
.buttons {
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.reset,
.submit {
  flex-shrink: 0;
  border: none;
}

.reset {
  width: 77px;
}

.submit {
  margin-left: 24px;
  padding-right: 16px;
  padding-left: 16px;
}

/* エリア検索部分 */
.areaSelect {
  position: relative;
  width: 132px;

  & select {
    height: 50px;
    width: 100%;
    padding: 0 45px 0 16px;
    appearance: none;
    outline: none;
    background-color: var(--BG-Base-Light-Primary);
    border: 1px solid var(--STROKE-Form-Normal);
    border-radius: 4px;
    color: var(--TEXT-Base-Dark-Primary);
    cursor: pointer;
  }

  & select::-ms-expand {
    display: none;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    pointer-events: none;
    right: 12px;
    width: 16px;
    height: 16px;
    background-size: contain;
    background-image: url(/img/icon/arrow_under_gray.svg);
    background-repeat: no-repeat;
  }
}

/* チーム検索部分 */
.filterTeamAll {
}

.teamDetail {
  display: flex;
  align-items: center;

  .logo {
    width: 36px;
    flex-shrink: 0;
    margin-right: 8px;
  }

  .name {
    flex: 1;
  }
}
