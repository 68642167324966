.link {
  margin-top: 32px;
  text-align: center;

  a {
    color: var(--TEXT-Base-Light-Primary);
    margin: 0 8px;
  }

  + .link {
    margin-top: 20px;
  }
}

.check {
  input {
    display: none;

    &:checked {
      + .checkText {
        &:before {
          background: var(--BG-Checkbox-Primary) url(/img/icon/check.svg) no-repeat 50% 50%;
          background-size: 14px 11px;
        }
      }
    }
  }
}

.checkText {
  display: inline-block;
  position: relative;
  padding-left: 32px;
  text-align: left;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    width: 24px;
    height: 24px;
    border: 1px solid var(--STROKE-Checkbox-Primary);
    border-radius: 4px;
  }

  a {
    color: var(--TEXT-Base-Light-Primary);
  }
}

.formItem {
  margin-bottom: 20px;
}

.flex {
  display: flex;

  > * {
    margin-right: 12px;
  }
}

.select {
  width: 120px;
  height: 50px;
  padding: 0 32px 0 12px;
  appearance: none;
  border: 1px solid var(--STROKE-Form-Normal);
  color: var(--TEXT-Base-Light-Primary);
  border-radius: 4px;
  background: var(--BG-Form-Dark) url(/img/icon/arrow_under.svg) no-repeat center right 12px;
  background-size: 10px 6px;
  font-size: 16px;

  &::-ms-expand {
    display: none;
  }

  &:focus {
    border-color: var(--STROKE-Form-Normal-Focus);
  }

  &:disabled {
    opacity: 1;
  }

  &.isEmpty {
    color: var(--TEXT-Form-Placeholder);
  }

  &.error {
    border-color: var(--STROKE-Form-Error);
    background-color: var(--BG-Form-Error);

    &:focus {
      border-color: var(--STROKE-Form-Error);
    }
  }
}

.selectSmall {
  width: 90px;
}
