.othersItem {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.othersItemTitle {
  font-weight: bold;
  font-size: 14px;
  color: var(--TEXT-Base-Light-Tertiary);
}

.othersItemBody {
  font-size: 16px;

  a {
    font-size: 14px;
    color: var(--TEXT-Base-Light-Tertiary);
    margin-left: 8px;

    &.pointBreakdownLink {
      display: inline-block;
      padding-left: 20px;
      background: url(/img/icon/alert_gray.svg) no-repeat 0 50%;
    }
  }

  em {
    font-style: normal;
    color: var(--TEXT-Base-Colored-Primary);
  }
}
