.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 42px;
  margin-bottom: 20px;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--STROKE-Base-Secondary);
  overflow-wrap: anywhere;
}

.titleText {
  flex: 1;
  font-size:20px;
  font-weight: bold;
}
