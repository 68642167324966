.wrapper {
  display: inline-block;
  position: relative;
  bottom: -2px;
  margin-left: 8px;
  vertical-align: middle;
  white-space: pre-wrap;
}

.box {
  position: absolute;
  bottom: calc(100% + 8px);
  z-index: 1;
  width: 62vw;
  max-width: 232px;
  padding: 8px 16px;
  background-color: var(--BG-Tooltip-Primary);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  font-size: 12px;

  @media (--more-than-tablet) {
    max-width: 232px;
  }

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    border: 6px solid transparent;
    border-top: 6px solid var(--BG-Tooltip-Primary)
  }
}

.left {
  left: -12px;

  &:after {
    left: 14px;
  }
}

.right {
  right: -12px;

  &:after {
    right: 14px;
  }
}

.btn {
  width: 16px;
  height: 16px;
  background: url(/img/icon/question.svg) no-repeat center center;
  background-size: 16px auto;
  padding: 0;
  border: none;
}

.hidden {
  display: none;
}
