.header {
  background-color: var(--BG-Base-Dark-Basic);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 62px;
  padding: 0 16px;

  @media (--more-than-tablet) {
    padding: 0 32px
  }
}

.headerFixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  padding-right: 130px;
}

.headerLogo {
  margin-right: 8px;
  width: 81px;

  a {
    display: block;
    color: var(--TEXT-Base-Light-Primary);
    text-decoration: none;
  }
}

.headerText {
  margin-top: 4px;
  font-size: 10px;
}
