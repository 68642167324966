.tabs {
  padding: 4px 16px 0;
  overflow-x: auto;
  background: var(--BG-Base-Dark-Tertiary);
  box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.2);
  border-radius: 24px 24px 0px 0px;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (--more-than-tablet) {
    & {
      padding: 16px 20px 0;
      border-radius: initial;
      box-shadow: none;
    }
  }
}

.tabList {
  display: flex;
}

.tabItem {
  flex-shrink: 0;
  padding: 16px 12px;
  font-size: 16px;
  cursor: pointer;
  color: var(--TEXT-Base-Light-Tertiary);

  @media (--more-than-tablet) {
    & {
      position: relative;
      padding: 16px 12px;
      background: transparent;
      border: none;
      color: var(--TEXT-Base-Light-Tertiary);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.tabActive {
    color: var(--TEXT-Tab-Primary);
    border-bottom: 2px solid var(--STROKE-Tab-Primary);

    @media (--more-than-tablet) {
      & {
        opacity: 1;
        text-decoration: none;
        border-bottom: none;

        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: 0 auto;
          width: 100%;
          height: 2px;
          background-color: var(--STROKE-Tab-Primary);
        }
      }
    }
  }
}
