.calendar {
  color: #fff;
  user-select: none;
}

.title {
  margin-bottom: 12px
}

.titleLabel {
  position: relative;
  font-size: 15px;
  cursor: pointer;
}

.titleBox {
  display: flex;
  justify-content: space-between;
}

.boxRight {
  display: flex;
}

.line {
  display: flex;
  justify-content: center;
  font-size: 14px;

  @media (--more-than-tablet) {
    font-size: 12px;
  }

  &.weekdayLine {
    font-size: 12px;
  }
}

.weekdayItem {
  display: flex;
  width: 56px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 36px;
  font-weight: bold;

  @media (--more-than-tablet) {
    height: 40px;
  }
}

.lineItem {
  padding-bottom: 12px;
  display: flex;
  height: 52px;
  width: 56px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
  border: none;

  .boxMonthly & {
    height: 44px;

    @media (--more-than-tablet) {
      height: 40px;
    }
  }
}

.active, .event {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    display: block;
    border-radius: 50%;
  }
}

.active {
  z-index: 2;

  &:after {
    top: 50%;
    transform: translate(-50%, -50%);
    width: 36px;
    height: 36px;
    z-index: -1;
    border: 1px solid var(--color-yellow);
  }
}

.event {
  padding: 10px 0;

  &:after {
    bottom: 0;
    transform: translateX(-50%);
    width: 6px;
    height: 6px;
    background-color: var(--color-yellow);
  }

  .disabled &:after {
    background-color: #999;
  }
}

.disabled {
  color: #666;
}

.box, .boxMonthly {
  background-color: var(--color-bg-gray);
}

.arrow {
  position: relative;

  @media (--more-than-tablet) {
    color: #ccc;
    font-size: 15px;
    text-decoration: underline;
  }

  .title .boxLeft & {
    font-weight: bold;
    padding-right: 16px;

    @media (--more-than-tablet) {
      text-decoration: none;
      cursor: text;
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 9px;
    height: 15px;
    margin: auto 0;
    background: url(/img/icon_arrow_left.svg) no-repeat center center;
    background-size: 9px 15px;
  }

  &.disabled {
    opacity: 0.6;

    @media (--more-than-tablet) {
      text-decoration: none;
    }
  }
}

.arrowPrev,
.arrowNext {
  background: none;
  border: none;
}

.arrowPrev {
  @media (--more-than-tablet) {
    padding-left: 14px;
  }

  &:after {
    left: 0;
  }
}

.arrowNext {
  margin-left: 36px;

  @media (--more-than-tablet) {
    margin-left: 16px;
    padding-right: 14px;
  }

  &:after {
    right: 0;
    transform: rotate(180deg) translateY(1px);
  }
}

.arrowUp,
.arrowDown {
  &:after {
    right: 0;

    @media (--more-than-tablet) {
      display: none;
    }
  }
}

.arrowUp {
  &:after {
    transform: rotate(90deg);
  }
}

.arrowDown {
  &:after {
    transform: rotate(-90deg);
  }
}

.container {
  @media (--more-than-tablet) {
    width: 100%;
  }
}
