.list,
.btn {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.btn {
  width: 100%;
  font-size: 15px;
}

.list {
  display: flex;
  column-gap: 4px;
}

.item {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.label {
  display: none;
}

.reset {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(/img/icon_delete.svg);
  cursor: pointer;
  z-index: 99;
}

.filter.select {
  & select {
    padding: 0 0 0 36px;
  }
}

.select {
  position: relative;
  border-radius: 4px;

  & select {
    height: 50px;
    width: 100%;
    padding: 0 0 0 32px;
    appearance: none;
    outline: none;
    background: none;
    border: none;
    color: var(--TEXT-Base-Dark-Primary);
    transform: scale(0.9);
    transform-origin: center left;
  }

  & select::-ms-expand {
    display: none;
  }

  &:after,
  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    pointer-events: none;
  }

  &:before {
    left: 12px;
  }
  &:after{
    right: 12px;
    width: 16px;
    height: 16px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(/img/icon/arrow_under_gray.svg);
  }
}

select.isEmpty {
  color: var(--TEXT-Form-Placeholder);
}

.notSelect{
  &:after{
    background: none;
  }
}

.match {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.detail {
  display: flex;
  align-items: center;
  color: #fff;
}

.team {
  display: flex;
  align-items: center;
}

.teamName {
  font-size: 14px;
  font-weight: bold;
}

.teamLogo {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.teamLeft {
  .teamLogo {
    margin-right: 4px;
  }
}

.teamRight {
  .teamLogo {
    margin-left: 4px;
  }
}

.detailDate {
  font-size: 12px;
  margin: 0 16px;
  text-align: center;

  @media (--more-than-tablet) {
    margin: 0 8px;
  }
}

.matchLink {
  flex-shrink: 0;
  margin-left: 8px;

  .btn{
    min-width: 72px;
    height: 33px;
    font-size: 12px;
  }
}


.area {
  pointer-events: none;

  &:before {
    width: 16px;
    height: 16px;
    background-size: 16px 16px;
    background-image: url(/img/icon/place_gray.svg);
  }
}

.team {
  pointer-events: none;

  &:before {
    width: 16px;
    height: 16px;
    background-size: 16px 16px;
    background-image: url(/img/icon/team_gray.svg);
  }
}

.filter {
  pointer-events: none;

  &:before {
    left: 12px;
    width: 16px;
    height: 15px;
    background-size: 16px 15px;
    background-image: url(/img/icon/filter.svg);
  }
}

.mergeList{
  column-gap: 0;
  border-radius: 4px;
  overflow: hidden;

  @media (--more-than-tablet) {
    overflow: unset;
  }

  .item{
    border: none;

    &:first-child{
      border-right: 1px solid #EEEEEE;

      @media (--more-than-tablet) {
        border-right: none;
      }
    }
  }
  .select{
    border-radius: 0px;

    @media (--more-than-tablet) {
      border-radius: 4px;
    }
  }
}

.keyVisualSearch {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  & :global(.l-main__innerWidth) {
    width: calc(91.46667% + 8px);

    @media (--more-than-tablet) {
      display: flex;
      max-width: 951px;
      border-radius: 8px;
      padding: 4px;
      background-color: var(--BG-Base-Dark-Primary);
    }
  }

  .list {
    border-radius: 8px;

    @media (--more-than-tablet) {
      flex: 1;
    }
  }

  .item{
    background: var(--BG-Form-Dark);
    border: 1px solid var(--STROKE-Form-Normal);
    border-radius: 4px;
  }

  .area {
    &:before {
      background-image: url(/img/icon/place.svg);
    }
  }

  .team {
    &:before {
      background-image: url(/img/icon/team.svg);
    }
  }

  .select {
    & select{
      color: var(--TEXT-Base-Light-Primary);

      &[class*="isEmpty"] {
        color: var(--TEXT-Form-Placeholder);
      }
    }

    &:after{
      background-image: url(/img/icon/arrow_under.svg);
    }
  }

  .notSelect{
    &:after{
      background: none;
    }
  }

  .reset {
    background-image: url(/img/icon/delete.svg);
  }
}

.sort {
  margin-bottom: 16px;

  & :global(.l-main__innerWidth) {
    @media (--more-than-tablet) {
      width: 100%;
    }
  }

  .list {
    @media (--more-than-tablet) {
      display: block;
      background-color: transparent;
      border-radius: 0px;
    }
  }

  .item {
    @media (--more-than-tablet) {
      background: none;
    }

    &:not(:last-child) {
      @media (--more-than-tablet) {
        margin-bottom: 20px;
      }
    }
  }

  .select {
    background-color: var(--BG-Base-Light-Primary);

    @media (--more-than-tablet) {
      margin-top: 8px;
    }
  }

  .reset {
    @media (--more-than-tablet) {
      top: calc(50% + 17px);
    }
  }
}

.textEllipsis {
  appearance: none;
  white-space: nowrap;
  overflow: hidden;
}
