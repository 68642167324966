.aboutCancel{
  border-radius: 4px;
  padding: 16px;
  background-color: var(--BG-Base-Dark-Tertiary);
}

.aboutCancelTitle{
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-size: 16px;
  color: var(--TEXT-Base-Light-Primary);

  &::before{
    content: "";
    display: block;
    width: 24px;
    height: 24px;
    background: url(/img/icon/alert_exclamation.svg) no-repeat center center / contain;
  }
}

.aboutCancelText{
  margin-top: 8px;
  padding-left: 46px;

  li{
    font-size: 16px;
    color: var(--TEXT-Base-Light-Secondary);
    line-height: 23.17px;
    text-indent: -1em;

    &::before{
      content: "・";
    }
  }
}
