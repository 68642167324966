.text {
  line-height: 1.7;

  &.gray {
    color: var(--TEXT-Base-Light-Secondary);
  }

  &.darkgray {
    color: var(--TEXT-Base-Dark-Tertiary);
  }

  &.red {
    color: var(--TEXT-Status-Error);
  }

  &.bold {
    font-weight: bold;
  }

  &.preWrap {
    white-space: pre-wrap;
  }
}

.size22 {
  font-size: 22px;
}

.size18 {
  font-size: 18px;
}

.size16 {
  font-size: 16px;
}

.size15 {
  font-size: 15px;
}

.size14 {
  font-size: 14px;
}

.size12 {
  font-size: 12px;
}

.size10 {
  font-size: 10px;
}

.size8 {
  font-size: 10px;
  transform: scale(0.8);
  transform-origin: center left;
}
